.sidebar-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .metismenu {
  display: flex;
  flex-direction: column;
}

.sidebar-nav .metismenu li + li {
  margin-top: 5px;
}

.sidebar-nav .metismenu li:first-child {
  margin-top: 5px;
}
.sidebar-nav .metismenu li:last-child {
  margin-bottom: 5px;
}

.sidebar-nav .metismenu > li {
  display: flex;
  flex-direction: column;
  position: relative;
}
.sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 13px 15px;
  color: #212529;
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.sidebar-nav .metismenu ul a {
  padding: 10px 15px 10px 30px;
}

.sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 45px;
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active,
.sidebar-nav .metismenu .mm-active > a {
  color: #0c63e4;
  text-decoration: none;
}

.sidebar-nav .metismenu .mm-active > a {
  background: #e7f1ff;
  border-radius: 3px;
}
