@import './css/pace.min.css';
@import './css/perfect-scrollbar.css';
@import './css/metisMenu.min.css';
@import './css/mm-vertical.css';
@import './css/simplebar.css';
@import './css/bootstrap.min.css';
@import './css/bootstrap-extended.css';
@import './css/main.css';
@import './css/responsive.css';
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap";
@import "https://fonts.googleapis.com/css?family=Material+Icons+Outlined";
@import 'leaflet/dist/leaflet.css';

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  -webkit-text-fill-color: #333 !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: #333 !important;
  box-shadow: 0 0 0 #fff !important;
  color: #333 !important;
}

@keyframes zoomIn {
  from {
      transform: scale(0.5);
      opacity: 0;
  }
  to {
      transform: scale(1);
      opacity: 0.8;
  }
}

.gonec{
  border-radius: 100px;
}
.login-img {
  border-radius: 50px;
}
.mobile-menu {
  display: none;
}
.bg-logo-img {
  background-color: #21252979;
  border: solid 2px #ffc107;
  padding: 2px 5px;
  width: 120px;
  height: auto;
  border-radius: 10px;
}
.shops .badge {
  padding: 6px !important;
}
.btn-outline-warning:hover { 
  color: white !important;
}
.btn-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
.btn-outline-warning.no-hover:hover {
  background-color: #fff !important;
  color: #ffc107 !important;
  cursor: default;
}
.modal {
  background-color: #3333336d;
}
.sidebar-wrapper .sidebar-headermobile-menu {
  z-index: 999 !important;
}
.border-r-none {
  border-radius: 0px !important;
}
.alert-style {
  position: fixed;
  right: 23px;
  top: 10px;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 1s ease-in-out;
  animation: zoomIn 0.5s ease-out;
}
.datepicker-group {
  border: solid 2px #ffc107;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.date-picker {
  left: 38px !important;
}
.diapason-picker {
  left: -32px !important;
}
.auth-basic-wrapper .card{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.alert-br {
  border-radius: 15px!important;
}
.card-stores {
  height: 950px;
  overflow: scroll;
}

@media only screen and (max-width: 1199px) {
  .main-wrapper {
    margin-top: 70px;
  }
  .mobile-menu {
    display: block;
  }
  .welcome-back-img {
    display: none;
  }
  .modal-dialog.modal-dialog-centered {
    width: 95% !important;
  }
  .primaery-menu-close {
    display: none;
  }
  .media-fs-25 {
    font-size: 25px !important;
  }
  .media-fs-23 {
    font-size: 23px !important;
  }
  .media-fs-17 {
    font-size: 17px !important;
  }
  .media-fs-12 {
    font-size: 12px !important;
  }
  .media-fs-14 {
    font-size: 14px !important;
  }
  .border-media {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
  .sm-btn {
    font-size: 14px;
    padding: 5px;
    height: 42px;
    line-height: 15px;
  }
  .alert-style {
    right: 0px;
    margin: 10px;
    top: 5px;
    z-index: 1000 !important;
  }
  .card-stores {
    height: 300px;
  }
}